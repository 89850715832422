/** @jsx jsx */
import { Flex, jsx, Text } from 'theme-ui'
import PropTypes from 'prop-types'
import CompareTableDropdown from '../CompareTableDropdown'
import { IoChevronDownSharp } from 'react-icons/io5'
import ImgLoader from '../ImgLoader'

const CompareTableHeader = ({
  name,
  description,
  image,
  collections,
  handleModelSelect,
  selectedModels,
  isFirst,
  isLast
}) => {
  return (
    <Flex
      sx={{
        flexFlow: 'column nowrap',
        alignItems: 'center',
        paddingBottom: [1, 3]
      }}
    >
      <div
        sx={{
          position: 'relative',
          maxWidth: '240px',
          width: '100%',
          margin: '0 auto'
        }}
      >
        <ImgLoader
          image={image}
          alt={image.description ?? image.title}
          imgStyle={{ objectFit: 'contain' }}
          forwardSx={{
            height: ['12rem', '15rem', null, '20rem'],
            width: '100%'
          }}
        />
      </div>
      <CompareTableDropdown
        collections={collections}
        handleModelSelect={handleModelSelect}
        selectedModels={selectedModels}
        label={name}
        isFirst={isFirst}
        isLast={isLast}
        icon={
          <IoChevronDownSharp
            sx={{ color: 'black', path: { strokeWidth: '100px' } }}
          />
        }
        forwardSx={{
          'summary > div': {
            mb: 0
          }
        }}
      />

      <Text
        as="p"
        variant="body"
        mt={1}
        mb={0}
        ml="-0.8rem"
        sx={{ fontSize: ['9px', '12px', '15px'], letterSpacing: '0.15rem' }}
      >
        {description}
      </Text>
    </Flex>
  )
}

CompareTableHeader.propTypes = {
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  selectedModels: PropTypes.arrayOf(PropTypes.string),
  handleModelSelect: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object
}

export default CompareTableHeader
