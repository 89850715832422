/** @jsx jsx */
import { graphql, navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { jsx } from 'theme-ui'
import { Container, Heading } from '@theme-ui/components'
import Layout from '~/components/Layout'
import Metadata from '../components/Metadata/index'
import CompareTable from '~/components/Generic/CompareTable'

const CompareBikesPage = ({
  data: {
    contentfulLineup: { collections: bikes }
  }
}) => {
  useEffect(() => {
    if (!bikes) {
      navigate('/')
    }
  }, [])

  return (
    <Layout>
      {bikes && (
        <>
          <Metadata title="Compare Bike Models" />

          <Container
            sx={{
              paddingTop: [4, 4],
              overflow: 'initial'
            }}
          >
            <Heading
              as="h1"
              variant="h1"
              sx={{
                textTransform: 'uppercase',
                fontSize: ['2.5rem', null, null, '4rem'],
                fontWeight: 'medium',
                marginBottom: 4,
                textAlign: 'center'
              }}
            >
              Compare Models
            </Heading>

            <CompareTable collections={bikes} />
          </Container>
        </>
      )}
    </Layout>
  )
}

export default CompareBikesPage

export const query = graphql`
  query CompareBikesPage {
    contentfulLineup(slug: { eq: "all-bikes" }) {
      id
      slug
      name
      collections {
        ... on ContentfulCollection {
          id
          title
          products {
            id
            slug
            name
            shortDescription
            mainImage {
              title
              gatsbyImageData(width: 240, layout: CONSTRAINED, quality: 80)
              description
            }
            specs {
              id
              name
              description {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        ... on ContentfulLineup {
          id
          name
        }
      }
    }
  }
`
