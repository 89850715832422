/** @jsx jsx */
import { Text, jsx, Flex, Button, Heading } from 'theme-ui'
import { IoAddSharp } from 'react-icons/io5'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import useOnClickOutside from '~/hooks/utils/use-on-click-outside'

const CompareTableDropdown = ({
  collections,
  selectedModels,
  handleModelSelect,
  label,
  icon,
  forwardSx = {},
  isFirst,
  isLast
}) => {
  const containerRef = useRef()
  const dropdownRef = useRef()
  const [isOpen, setIsOpen] = useState()
  useOnClickOutside(dropdownRef, () => setIsOpen(false))

  const availableCollections = collections.map(collection => {
    // Products are available if they're not selected
    const products = collection.products.map(model => ({
      ...model,
      isAvailable: !selectedModels.includes(model.id)
    }))

    // Collections are available if they have available products
    const isAvailable = products.filter(model => model.isAvailable).length

    return { ...collection, isAvailable, products }
  })

  useEffect(() => {
    if (isOpen !== false) return

    containerRef.current.removeAttribute('open')
    setIsOpen(undefined)
  }, [isOpen])

  return (
    <details sx={{ position: 'relative', ...forwardSx }} ref={containerRef}>
      <summary
        sx={{
          cursor: 'pointer',
          outline: 'none',
          '::marker': { content: '""' },
          '::-webkit-details-marker': { opacity: 0 }
        }}
      >
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            mb: '15px'
          }}
        >
          <Text
            variant="h2"
            sx={{
              marginRight: 2,
              textTransform: 'uppercase',
              fontSize: ['2rem', '2.5rem', '1.5rem', '3rem'],
              fontWeight: 'medium',
              letterSpacing: ['0.25', null, null, '0.45rem']
            }}
          >
            {label}
          </Text>

          {icon}
        </Flex>
      </summary>

      <nav
        sx={{
          position: 'absolute',
          top: '130%',
          left: [isFirst ? '70%' : isLast ? '30%' : '50%', null, '50%'],
          width: '26rem',
          maxHeight: '32rem',
          backgroundColor: 'white',
          boxShadow: '0 5px 22px #00000012',
          overflowY: 'auto',
          transform: 'translateX(-50%)',
          overscrollBehavior: 'contain'
        }}
        ref={dropdownRef}
      >
        {availableCollections.map(collection => (
          <Flex
            as="section"
            sx={{
              flexFlow: 'column nowrap',
              alignItems: 'stretch',
              'button:not(:last-of-type)': {
                borderBottom: theme => `1px solid ${theme.colors.gray70}`
              }
            }}
            key={collection.id}
          >
            <Heading
              as="h3"
              variant="h3"
              sx={{
                position: 'sticky',
                top: 0,
                left: 0,
                fontSize: 1,
                color: 'white',
                padding: 2,
                paddingLeft: '1.6rem',
                backgroundColor: collection.isAvailable
                  ? 'black'
                  : 'borderInactive'
              }}
            >
              {collection.title}
            </Heading>

            {collection.products.map(
              model =>
                model.specs && (
                  <Button
                    onClick={() => {
                      setIsOpen(false)
                      handleModelSelect(model)
                    }}
                    variant="clear"
                    sx={Object.assign(
                      {
                        textAlign: 'left',
                        padding: '0.8rem 1.6rem',
                        borderRadius: 0
                      },
                      !model.isAvailable
                        ? {
                            pointerEvents: 'none',
                            color: 'borderInactive'
                          }
                        : {}
                    )}
                    key={model.id}
                  >
                    {model.name}
                  </Button>
                )
            )}
          </Flex>
        ))}
      </nav>
    </details>
  )
}

CompareTableDropdown.propTypes = {
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  selectedModels: PropTypes.arrayOf(PropTypes.string),
  handleModelSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  icon: PropTypes.node
}

CompareTableDropdown.defaultProps = {
  selectedModels: [],
  label: 'Add Model',
  icon: <IoAddSharp sx={{ path: { strokeWidth: '70px' } }} />
}

export default CompareTableDropdown
