/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { PropTypes } from 'prop-types'
import { Fragment, useEffect, useState } from 'react'
import CompareTableHeader from '../CompareTableHeader'
import CompareTableDropdown from '../CompareTableDropdown'
import useMatchMedia from '~/hooks/utils/useMatchMedia'
import useBreakPointValues from '~/hooks/utils/use-breakpoint-values'

const CompareTable = ({ collections }) => {
  const isWideViewport = useMatchMedia('(min-width: 52em)')
  const numTds = useBreakPointValues([2, 2, 3, 3])

  const models = collections
    .reduce((acc, collection) => [...acc, ...collection.products], [])
    .filter(model => model.specs)

  const [compareModels, setCompareModels] = useState([models[0]])

  const allSpecs = compareModels.reduce((acc, model) => {
    return [...acc, ...model.specs]
  }, [])
  const keys = [...new Set([...allSpecs.map(s => s.name)])]

  const comparative = keys.reduce((acc, key) => {
    const values = compareModels.map(model => {
      const match = model.specs.find(s => s.name === key)
      return match ? match.description.childMarkdownRemark.html : '-'
    })

    acc[key] = values
    return acc
  }, {})

  const addModel = model => {
    setCompareModels([...compareModels, model])
  }

  const updateModel = (model, index) => {
    const models = compareModels.map((m, i) => (i === index ? model : m))
    setCompareModels(models)
  }

  useEffect(() => {
    if (!isWideViewport && compareModels.length > 2) {
      setCompareModels(compareModels.slice(0, 2))
    }
  }, [isWideViewport, compareModels])

  return (
    <table
      sx={{
        width: '100%',
        minHeight: '12rem',
        tableLayout: 'fixed',
        borderCollapse: 'collapse',
        borderSpacing: 0,
        marginTop: compareModels.length ? 0 : '12rem',
        marginBottom: '10rem',
        tr: {
          verticalAlign: 'top'
        },
        th: {
          padding: ['1.5rem', '1.5rem 1.5rem 0', null, '0 1.5rem']
        },
        td: {
          padding: ['1.5rem', null, '2.8rem']
        }
      }}
    >
      <thead
        sx={{
          backgroundColor: 'background',
          position: 'sticky',
          top: 'var(--nav-bar-height)',
          borderBottom: theme => [
            null,
            null,
            `1px solid ${theme.colors.borderInactive}`
          ],
          zIndex: 5
        }}
      >
        <tr>
          <th
            sx={{
              display: ['none', null, compareModels.length ? 'initial' : 'none']
            }}
          >
            &nbsp;
          </th>

          {compareModels.map((model, index) => (
            <th scope="col" key={model.id}>
              <CompareTableHeader
                collections={collections}
                handleModelSelect={model => updateModel(model, index)}
                selectedModels={compareModels.map(model => model.id)}
                models={models}
                name={model.name}
                description={model.shortDescription}
                image={model.mainImage}
                isFirst={index === 0}
                isLast={index === compareModels.length - 1}
              />
            </th>
          ))}
          <td
            sx={{
              display: [
                compareModels.length === 2 ? 'none' : 'table-cell',
                null,
                compareModels.length === 3 ? 'none' : 'table-cell'
              ],
              position: 'relative'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                bottom: compareModels.length ? ['2rem', null, '2.5rem'] : 0,
                width: '100%'
              }}
            >
              <CompareTableDropdown
                collections={collections}
                handleModelSelect={addModel}
                selectedModels={compareModels.map(model => model.id)}
                isLast={true}
              />
            </Box>
          </td>
        </tr>
      </thead>

      <tbody>
        {keys.map((k, i) => (
          <Fragment>
            <tr sx={{ display: [null, null, 'none'] }}>
              <td
                colSpan="2"
                sx={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontWeight: 'medium',
                  fontSize: ['1.5rem', null, '1.3rem', '2rem'],
                  letterSpacing: '0.25rem'
                }}
              >
                {k}
              </td>
            </tr>
            <tr
              sx={{
                position: 'relative',
                ':not(:first-of-type)': {
                  borderTop: theme => `1px solid rgba(112,112,112,0.15)`
                },
                ':first-of-type td': {
                  paddingTop: ['8rem', null, 3]
                },
                ':first-of-type td::before': {
                  top: '1.6rem'
                },
                ':first-of-type td::after': {
                  content: '""',
                  display: ['block', null, 'none'],
                  position: 'absolute',
                  top: '5.5rem',
                  left: 0,
                  right: 0,
                  width: '100%',
                  height: '1px',
                  backgroundColor: 'borderInactive'
                },
                td: {
                  paddingBottom: ['8rem', null, 3]
                }
              }}
              key={k}
            >
              <th
                scope="row"
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 'medium',
                  fontSize: ['1.5rem', null, '1.3rem', '2rem'],
                  display: ['none', null, 'inline-block']
                }}
              >
                {k}
              </th>

              {comparative[k].map((v, i) => {
                return (
                  <Fragment>
                    <td
                      sx={{
                        textAlign: 'left',
                        position: 'relative',
                        p: {
                          margin: 0
                        }
                      }}
                      key={`${i}-${v}`}
                      dangerouslySetInnerHTML={{ __html: v }}
                    ></td>
                    {/* Display an extra td if there are less than 3 models being displayed, so the tr border is full width */}
                    {comparative[k].length < numTds &&
                    i === comparative[k].length - 1 ? (
                      <td sx={{ position: 'relative' }}></td>
                    ) : null}
                  </Fragment>
                )
              })}
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  )
}

CompareTable.propTypes = {
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          shortDescription: PropTypes.string.isRequired,
          mainImage: PropTypes.shape({
            gatsbyImageData: PropTypes.object
          }).isRequired,
          specs: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
              description: PropTypes.shape({
                text: PropTypes.string.isRequired
              })
            })
          )
        })
      ).isRequired
    })
  )
}

export default CompareTable
