import { useState, useEffect } from 'react'
import { useWindowSize } from '~/hooks/utils/use-window-size'

const useBreakPointValues = values => {
  const { width } = useWindowSize()
  const [currentVal, setCurrentVal] = useState(values[0])

  const breakpoints = [576, 768, 1024, 1600]

  useEffect(() => {
    if (width < breakpoints[0]) {
      setCurrentVal(values[0])
    } else if (width >= breakpoints[0] && width < breakpoints[1]) {
      setCurrentVal(values[1])
    } else if (width >= breakpoints[1] && width < breakpoints[2]) {
      setCurrentVal(values[2])
    } else if (width >= breakpoints[2] && width < breakpoints[3]) {
      setCurrentVal(values[3])
    } else {
      setCurrentVal(values[values.length - 1])
    }
  }, [width, breakpoints, values])
  return currentVal
}

export default useBreakPointValues
